@import 'src/styles/typography';
@import 'src/styles/themes';

.avatar {
  cursor: pointer;

  &Image {
    height: 40px;
    width: 40px;
    border-radius: 20px;
    object-fit: cover;
    background: $background-color;
    display: flex;
    justify-content: center;
    align-items: center;

    span {
      svg {
        font-size: 25px;
      }
    }
  }

  &Menu {
    &__item {
      @extend .fontBase;
      display: flex;
      align-items: center;

      span {
        color: $primary-color !important;
      }

      svg {
        height: 20px;
        width: 20px;

        path {
          fill: $primary-color;
        }
      }
    }
  }

  &__info {
    padding: 15px;
    padding-bottom: 0;
    margin-bottom: 22px;
  }

  &__username {
    @extend .fontBase;
    margin: 0;
    margin-bottom: 8px;
  }

  &__position {
    @extend .fontBase;
    margin: 0;
    font-weight: 400;
    color: rgba($black-color, 0.5);
  }
}
