@import 'src/styles/themes';
@import 'src/styles/typography';

.drawerWrapper {
  :global(.ant-drawer-body) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 20px;
  }

  :global(.ant-drawer-content) {
    background: #fff;
  }

  :global(.anticon-close) {
    color: #011f30;
  }
}

.languageWrapper {
  :global(.ant-dropdown-trigger) {
    color: #011f30;
  }
}

.userInfoWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 15px;
}

.avatarImg {
  height: 60px;
  width: 60px;
  border-radius: 50%;
  object-fit: cover;
  background: #e2e2e2;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}

.avatarIcon {
  height: 60px;
  width: 60px;
  border-radius: 50%;
  object-fit: cover;
  background: #e2e2e2;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;

  span svg {
    font-size: 34px;
  }
}

.userName {
  color: #011f30;
  line-height: 20px;
  font-weight: bold;
  font-size: 16px;
  margin: 0;
  max-width: 216px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.userPosition {
  line-height: 20px;
  font-size: 16px;
  margin: 0;
  color: rgba(1, 31, 48, 0.5);
  max-width: 216px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.nav {
  position: relative;
  width: 100%;
}

.navItem {
  @extend .fontBase;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #011f30;
  height: 44px;
  text-decoration: none;
  margin-top: 12px;
  border-radius: 8px;
  padding: 0 15px;
  background: rgba(80, 80, 80, 0.1);

  svg {
    margin-right: 11px;
    * {
      fill: #011f30;
    }
  }

  &:hover,
  &:active,
  &:focus {
    background: rgba(80, 80, 80, 0.3);
  }
}

.activeNavItem {
  background: rgba(80, 80, 80, 0.3);
}

.logout {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-width: 104px;
  flex: 40px 0 0;
  border-radius: 4px;
  color: #fff;
  transition: background-color 0.3s;
  background-color: #4e8cae;
  margin-top: 15px;
  border: 0;

  svg {
    margin-right: 10px;
  }
}
