@import 'src/styles/themes';
@import 'src/styles/typography';

.card {
  padding: 24px 13px 24px 24px;
  width: 100%;
  background-color: $white-color;
  border-radius: 4px;

  &_isUsed {
    @extend .fontBase;
    font-weight: 500;
    color: $blue-color;

    svg {
      margin-right: 5px;

      path {
        fill: $blue-color;
      }
    }
  }

  &__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
    align-items: flex-start;
  }

  &__title {
    @extend .fontBase;
    color: inherit;
    margin-bottom: 0;

    a {
      color: inherit;
    }
  }

  &__image {
    margin-bottom: 8px;

    img {
      max-width: 80px;
      max-height: 80px;
    }
  }

  &__description {
    @extend .fontBase;
    font-weight: 400;
    margin-bottom: 0;
    color: inherit;
  }

  &__extra {
    display: flex;
    align-items: center;
  }

  &__button {
    padding: 0;
    display: flex;
    justify-content: center;
    margin-right: 12px;
    height: 16px;

    svg {
      margin-right: 0;
    }

    &:hover {
      background-color: transparent;
    }
  }

  &__ellipsis {
    font-size: 28px;
    cursor: pointer;
  }

  &__star {
    font-size: 16px;

    &_fill {
      @extend .card__star;

      path {
        fill: $red-color;
      }
    }
  }

  &__dropdownItem {
    @extend .fontBase;
    font-weight: 400;
    margin-bottom: 0;
    padding: 5px;
  }
}
