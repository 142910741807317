@font-face {
  font-family: 'ParnterCondesedUltra';
  src: url('../assets/fonts/PARTNERULTRACONDENSED.TTF') format('truetype');
  font-weight: bold; /* 700 */
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('../assets/fonts/Roboto-Regular.woff2') format('woff2'),
    url('../assets/fonts/Roboto-Regular.woff') format('woff');
  font-weight: normal; /* 400 */
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('../assets/fonts/Roboto-Bold.woff2') format('woff2'),
    url('../assets/fonts/Roboto-Bold.woff') format('woff');
  font-weight: bold; /* 700 */
  font-style: normal;
  font-display: swap;
}
