.burgerButton {
  display: none;
  margin-left: 11px;
  width: 40px;
  height: 34px;
  color: #fff;
  border: 0;
  background: rgba(255, 255, 255, 0.1);
  line-height: 22px;
  vertical-align: middle;
  font-weight: bold;
  font-size: 22px;
  border-radius: 8px;
  cursor: pointer;
  padding: 6px 9px;

  &:hover {
    background: rgba(255, 255, 255, 0.2);
  }

  @media (max-width: 768px) {
    display: inline-block;
  }
}
