@import './themes';

.fontLogo {
  line-height: 16px;
  font: {
    family: 'ParnterCondesedUltra';
  }
}

.fontBase {
  color: $black-color;
  line-height: 20px;
  font: {
    family: 'Roboto';
    weight: 700;
    size: 16px;
    style: normal;
  }
}

.fontBaseBold {
  @extend .fontBase;
  color: $primary-color;
  line-height: 28px;
  font: {
    weight: 700;
    size: 24px;
  }
}
