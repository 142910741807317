@import 'src/styles/typography';
@import 'src/styles/themes';

.buttonContainer {
  display: flex;

  button {
    width: 100%;
    height: 44px;

    &:first-child {
      margin-right: 20px;
    }
  }
}

.checkboxContainer {
  margin-bottom: 32px;
}

.errors {
  @extend .fontBase;
  margin-bottom: 15px;
  color: #ff4d4f;
  font-weight: 500;
  font-size: 14px;
}

.loader {
  padding: 0;
  font-size: 20px;

  svg {
    margin: 0;
    fill: $primary-color;
  }
}
