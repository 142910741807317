@import 'src/styles/typography';
@import 'src/styles/themes';

.cardLayout {
  margin-bottom: 16px;

  &:last-child {
    margin-bottom: 0;
  }

  &__title {
    @extend .fontBaseBold;
    color: inherit;
  }

  &__cards {
    display: flex;
    flex-wrap: wrap;

    & > a {
      margin-right: 16px;
      margin-top: 16px;

      @media screen and (max-width: 600px) {
        margin-right: 0;
      }
    }
  }
}

.mainLayout {
  padding: 40px;
  min-height: calc(100vh - 75px);

  @media screen and (max-width: 768px) {
    padding: 40px 16px;
  }
}

.profileSectionLayout {
  margin-bottom: 50px;

  &:last-child {
    margin-bottom: 0;
  }

  &__title {
    @extend .cardLayout__title;
    margin-bottom: 20px;
  }

  &__container {
    display: flex;
    gap: 16px;

    & > div {
      min-width: calc(50% - 8px);
      display: inline-flex;
    }

    @media screen and (max-width: 724px) {
      flex-direction: column;
      gap: 0px;
    }
  }
}
