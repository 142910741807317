@import 'src/styles/themes';

.input {
  height: 50px;
  border-radius: 4px;
  border: 2px solid rgba(0, 59, 92, 0.2);
}

.upload {
  position: relative;
  background-size: contain !important;
  background-repeat: no-repeat !important;
  opacity: 0.8;
  background-size: cover !important;

  &__error {
    position: absolute;
    top: 103%;
    color: #da291c;
    line-height: 1;
  }

  &__icon {
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
    font-size: 34px;

    &:hover {
      color: $blue-color;
    }
  }
}
