@import 'src/styles/typography';
@import 'src/styles/themes';

.searchBar {
  margin-bottom: 32px;

  & > span {
    height: 48px;
    border-radius: 4px;
    @extend .fontBase;
  }

  input {
    margin-left: 6px;
  }
}
