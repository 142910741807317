@import 'src/styles/typography';
@import 'src/styles/themes';

.page {
  &__container {
    display: flex;
  }

  &__form {
    margin: 0 auto;
    max-width: 570px;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 20px 80px;

    @media screen and (max-width: 600px) {
      padding: 20px;
    }
  }

  &__image {
    width: 50%;
    min-height: 100vh;
    background-position: left;
    background-repeat: no-repeat;
    background-image: url('../../assets/images/AuthPage.png');
    background-size: auto 100%;

    @media screen and (max-width: 900px) {
      display: none;
    }
  }

  &__logo {
    margin-bottom: 57px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__welcome {
    @extend .fontBaseBold;
    color: $black-color;
    margin-bottom: 32px;
  }
}
