@import 'src/styles/themes';
@import 'src/styles/typography';

.header {
  padding: 0 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 72px;
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
  background-color: $primary-color;

  @media screen and (max-width: 768px) {
    padding: 0 16px;
  }

  &__logo {
    display: flex;
    align-items: center;

    @media (max-width: 768px) {
      width: 100%;
      justify-content: space-between;
    }
  }

  &__nav {
    display: flex;
    align-items: center;

    @media (max-width: 768px) {
      display: none;
    }
  }

  &__link {
    @extend .fontBase;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    color: $white-color;
    height: 44px;
    margin-right: 24px;
    border-radius: 8px;
    padding: 0 15px;

    svg {
      margin-right: 11px;
    }

    &:hover {
      color: $white-color !important;
      background: rgba($white-color, 0.1);
    }

    &_active {
      background: rgba($white-color, 0.1);
      border-radius: 8px;
    }

    @media screen and (max-width: 768px) {
      display: none;
    }
  }

  &__lang {
    margin-right: 24px;
  }
}

.switchTheme {
  margin-left: 14px;
}
