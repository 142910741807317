@import 'src/styles/typography';

.checkboxLabel {
  @extend .fontBase;
}

.checkboxContainer {
  display: flex;
  align-items: center;
}
