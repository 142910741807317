@import 'src/styles/typography';
@import 'src/styles/themes';

.logoContainer {
  width: auto;
  height: 55px;
  display: flex;
  align-items: center;
}

.logoImage {
  width: 100%;
  height: 100%;
}
