@import 'src/styles/themes';
@import 'src/styles/typography';

.tags {
  &__container {
    margin-top: 20px;
    margin-bottom: 32px;
  }

  &__button {
    @extend .fontBase;
    color: $blue-color !important;
    margin-right: 10px;
    font: {
      size: 14px;
      weight: 400;
    }
    line-height: 32px;
    border: 2px solid rgba($blue-color, 0.8);
    border-radius: 100px;

    &:hover,
    &:focus {
      border-color: rgba($blue-color, 0.8) !important;
    }

    &_active {
      background-color: $blue-color;

      span {
        color: $white-color;
      }

      &:focus {
        background: $blue-color;
      }

      &:hover {
        background: $blue-color;
      }
    }
  }
}
