@import 'src/styles/typography';
@import 'src/styles/themes';

.modal {
  &__description {
    @extend .fontBase;
    color: inherit;
    font-weight: 400;

    img {
      max-width: 480px !important;
      max-height: 425px !important;
      object-fit: contain !important;
    }
  }

  &__button {
    color: $white-color;

    &_confirm {
      @extend .modal__button;
      color: $white-color;
    }
  }
}
