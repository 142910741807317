.ant-checkbox {
  top: 0px !important;

  &-inner {
    width: 24px !important;
    height: 24px !important;

    &::after {
      left: 35% !important;
    }
  }
}

.ant-switch {
  &-inner {
    display: flex !important;
  }

  &-checked {
    background-color: rgba(0, 0, 0, 0.25) !important;
  }

  &-handle::before {
    background-color: #4e8cae !important;
  }
}
