.button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;

  &:hover span {
    color: none;
  }

  svg {
    margin-right: 11px;
  }
}
