@import 'src/styles/typography';
@import 'src/styles/themes';

.language {
  @extend .fontBase;
  cursor: pointer;

  &__item {
    @extend .fontBase;
    padding: 6px 18px;
  }

  &_white {
    color: $white-color;
  }
}
